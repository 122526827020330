import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Icon,
  NavLink,
  NavLinkText,
  NavItem,
  ChildNavLink,
  ParentNavLink,
} from './AppSidebarNav.style';
import {isMobileOrTablet} from '../utils/isMobileOrTablet';

export const AppSidebarNav = ({items, setSidebarShow}) => {
  const location = useLocation();

  const navLink = (name, icon, selectedIcon, to, isChild) => {
    const navigate = useNavigate();
    const isSelected = location.pathname.split('/')[1] == to?.split('/')[1];
    const source = isSelected ? selectedIcon : icon;
    const NavIcon = () => {
      return <Icon src={source} className="navIcon" />;
    };

    if (isChild)
      return (
        <ChildNavLink
          onClick={() => {
            if (isMobileOrTablet()) {
              setSidebarShow(false);
            }
            navigate(to);
          }}>
          {source && <NavIcon />}
          <NavLinkText>{name && name}</NavLinkText>
        </ChildNavLink>
      );

    return (
      <ParentNavLink
        onClick={() => {
          if (isMobileOrTablet()) {
            setSidebarShow(false);
          }
          navigate(to);
        }}>
        {source && <NavIcon />}
        <NavLinkText>{name && name}</NavLinkText>
      </ParentNavLink>
    );
  };

  const navItem = (item, index, isChild) => {
    const {name, icon, selectedIcon, to, ...rest} = item;
    const isSelected = location.pathname.split('/')[1] == to.split('/')[1];

    return (
      <NavItem
        {...(to &&
          !rest.items && {
            component: NavLink,
          })}
        key={index}
        to={to}
        {...rest}
        isSelected={isSelected}>
        {navLink(name, icon, selectedIcon, to, isChild)}
      </NavItem>
    );
  };
  const navGroup = (item, index) => {
    const {component, name, icon, ...rest} = item;
    const Component = component;
    let isMenuSelected = false;

    item?.items?.forEach(child => {
      if (location.pathname.split('/')[1] == child.to.split('/')[1]) {
        isMenuSelected = true;
      }
    });

    return (
      <Component
        idx={String(index)}
        key={index}
        name={name}
        icon={icon}
        toggler={navLink(name, icon)}
        isMenuSelected={isMenuSelected}
        {...rest}>
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index, true),
        )}
      </Component>
    );
  };

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
    </React.Fragment>
  );
};

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
