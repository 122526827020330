import theme from 'src/theme/Theme';
import styled from 'styled-components';
const ChatMainContainer = styled.div`
  border: 1px solid ${theme.colors.NEUTRAL_MEDIUM};
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const ChatActivityIndicatorStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'Translate(-50%, -50%)',
};

export { ChatActivityIndicatorStyle, ChatMainContainer };

