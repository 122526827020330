const routeLink = {
  verifyResetPasswordTokenLink: '/v1/auth/verify-reset-password-token',
  verifyAuthenticatorOtp:'/v1/verifyAuthenticatorOtp',
  verifyAuthenticatorOtpAfterPasswordReset:'/v1/verifyAuthenticatorOtpAfterPasswordReset',
  resetPasswordLink: '/v1/auth/reset-password',
  changePasswordLink: '/v1/auth/change-existing-password',
  loginLink: '/login',
  forgotPasswordLink: '/v1/auth/forgot-password',
  getUser: '/v1/users',
  addPractice: '/v1/practices',
  practiceLink: '/v1/practices/',
  addUser: '/v1/user/register',
  editUser: '/v1/users',
  newRequest: '/v1/newrequests',
  getOneLinerAlert: '/v1/one-liner-alert',
  getPatient: '/v1/patients',
  getPatientChatGroups: '/v1/getpatientchatgroups',
  patientDocuments: '/v1/patientDocuments',
  getTCMText: '/v1/getTCMText',
  getTeamList: '/v1/teamlist',
  getChatMembers: '/v1/chatmembers',
  getChatGroups: '/v1/getchatgroups',
  getAddressesFromZip: '/v1/getaddressesfromzip',
  getAuditLogs: '/v1/auditLogs',
  getEmployerTypes: '/v1/employerTypes',
  getFacilities: '/v1/facilities',
  getFacilityWithTaskCount: '/v1/getFacilityWithTaskCount',
  updateOutPatientDiagnosesStatus:'/v1/updateOutPatientDiagnosesStatus',
  updateOutPatientMedicationStatus:'/v1/updateOutPatientMedicationStatus',
  addGroupMember: '/v1/addgroupmember',
  addGroupMembersSuggestion: '/v1/addgroupmember/suggestions',
  removeGroupMember: '/v1/removegroupmember',
  resendInviteLink: '/v1/auth/resend-invite',
  getUserBasedOnGroup: '/v1/getUserBasedOnGroup',
  addCareTeamMember: '/v1/patient/add-care-team-member',
  getLocationTypes: '/v1/locationTypes',
  getTCMEncounter: '/v1/encounters',
  editPatient: '/patient/details/edit',
  getTransitionalEvent: '/v1/transitionalEvents',
  getContactMode: '/v1/contactModes',
  getCareTeamMember: '/v1/careTeamMembers',
  removeCareTeamMember: '/v1/patient/remove-care-team-member',
  getTCMTask: '/v1/TcmTasks',
  completeVisitTask: '/v1/VisitTasks',
  getContactPerson: '/v1/contactPeople',
  getContactAttempt: '/v1/contactAttempts',
  getOutPatientDiagnoses: '/v1/patientDiagnoses',
  getOutPatientMedication: '/v1/medications',
  uploadTcmDocument: `/v1/upload/tcm-document`,
  getMedicine: '/v1/medicines',
  getFacilityWithMyPatients: '/v1/getFacilityWithMyPatients',
  patientWiseCaseTeamMembers: '/v1/patientWiseCaseTeamMembers',
  getUserType: '/v1/userTypes',
  requestType: '/v1/requesttypes',
  updateLastMessageTimetoken: '/v1/updatelastmessagetimetoken',
  insertionLogs: '/v1/insertionlogs',
  errorLogs: '/v1/errorlogs',
  getFailedInsertionPatientData : "/v1/patient/import/errors",
  downloadSample: '/v1/patient/download-template',
  form1823:"/v1/form1823tasks",
  completeForm1823:"/v1/upload/form1823tasks",
  facilityPatients:"/v1/facilityPatients",
  careTeamPatients:"/v1/careTeamPatients",
  fetchClinicalSummary:"/v1/patient/fetchClinicalSummary",
  cronSettings:"/v1/cronSettings",
};

export default routeLink;
