export const USER_TYPE={
    ADMIN:'Admin',
    FACILITY_ADMIN:'Facility admin',
    FACILITY_USER:'Facility user',
    PRACTICE_ADMIN:'Practice admin',
    PRACTICE_USER :"Practice user",
    MEDICAL_PROVIDER:'Medical provider',
}

export const USER_TYPE_ID =  {
    ADMIN:'65c4ac6407527c0abd975986',
    FACILITY_ADMIN:'65c4ac6407527c0abd975987',
    FACILITY_USER:'65c4ac6407527c0abd975988',
    PRACTICE_ADMIN:'65c4ac6407527c0abd975989',
    PRACTICE_USER:'65d85020a642fc1b6a9595cf',
    MEDICAL_PROVIDER:'65c4ac6407527c0abd975985',
}