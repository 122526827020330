import {AddContactAttemptsModal} from '../patient/components/AddContactAttempts.modal';
import {CompleteVisitTask} from '../patient/components/CompleteTCMVisit.modal';
import {AddMedicineModal} from '../patient/components/addMedicine.modal';
import {AddMedication} from '../patient/screens/AddMedication';
import {PatientDetails} from '../patient/screens/PatientDetails';
import {TaskTable} from './screens/TaskTable';
import CompleteMedication from '../patient/components/CompleteMedication';
import {TcmNotesModal} from '../patient/components/TcmNotes.modal';
import {Form1823} from './screens/Form1823';
import {FormRejectReason} from './screens/formRejectReason';
const TaskRoutes = [
  {
    name: 'Tasks',
    path: '/tasks',
    component: TaskTable,
    children: [
      {
        name: 'Complete Visit',
        path: '/tasks/complete-visit/:taskId',
        component: CompleteVisitTask,
      },
      {
        name: 'Add Contact Attempts',
        path: '/tasks/:EncounterId/:TaskId/add-contact-attempts',
        component: AddContactAttemptsModal,
        screenOptions: {
          modalSize: 'large',
        },
      },
      {
        name: 'Tcm Notes',
        path: '/tasks/tcm-notes',
        component: TcmNotesModal,
        screenOptions: {
          modalSize: 'xlarge',
        },
      },
    ],
  },
  {
    name: 'Patient Details',
    path: '/tasks/patient-details',
    component: PatientDetails,
  },
  {
    name: 'Medication Task',
    path: '/tasks/medication-task',
    component: CompleteMedication,
  },
  {
    name: 'Add Medication',
    path: '/tasks/medication-task/add-medication',
    component: AddMedication,
    children: [
      {
        name: 'Add Medicine',
        path: '/tasks/medication-task/add-medication/medicine',
        component: AddMedicineModal,
      },
    ],
  },
  {
    name: 'Form 1823',
    path: '/tasks/form1823/:taskId',
    component: Form1823,
    children: [
      {
        name: 'Reject Reason',
        path: '/tasks/form1823/:taskId/reject-reason',
        component: FormRejectReason,
        screenOptions: {
          modalSize: 'small',
        },
      },
    ],
  },
];

export default {
  stack: TaskRoutes,
};
