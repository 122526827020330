import theme from 'src/theme/Theme';
import styled from 'styled-components';

const MessageMainContainer = styled.div`
  padding: 5px;
  width: fit-content;
  max-width: 55%;
  float: ${props => (props.isItMe ? 'right' : 'left')};
  margin: ${props => (props.isItMe ? '5px 5px 5px 0' : '5px 0 5px 5px')};
  line-height: 18px;
  border-radius: 8px;
  background-color: ${theme.colors.ICON_BACKGROUND_COLOR};
  white-space: pre-wrap;
`;
const MessageName = styled.div`
  font-size: 12.2px;
  font-weight: 700;
  color: ${theme.colors.NEUTRAL_HIGH};
`;
const MessageText = styled.text`
  word-wrap: break-word;
  font-size: 14.2px;
`;

const MessageTime = styled.div`
  float: right;
  padding: 0 7px;

  color: ${theme.colors.NEUTRAL_MEDIUM};
  font-size: 11px;
`;

export {MessageMainContainer, MessageName, MessageText, MessageTime};
