import styled from 'styled-components';
import theme from '../theme/Theme';

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const NavLink = styled.div`
  cursor: pointer;
`;

const ChildNavLink = styled.div`
  padding-left: 20px;
  cursor: pointer;
`;

const ParentNavLink = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const NavLinkText = styled.text`
  padding-left: 8px;
`;

const NavItem = styled.div`
  background-color: ${props =>
    props.isSelected
      ? theme.colors.BACKGROUND
      : theme.colors.SIDEBAR_BACKGROUND};
  color: ${props =>
    props.isSelected
      ? theme.colors.SIDEBAR_BACKGROUND_MEDIUM
      : theme.colors.BACKGROUND};
  padding-left: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 12px;
`;

export { ChildNavLink, Icon, NavItem, NavLink, NavLinkText, ParentNavLink };

