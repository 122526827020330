export const hospitalizationDiagnoseStyle = {
    mainContainer: {
      flex: 1,
      overflow: 'hidden',
    },
    actionContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap:20
    },
  };
  