import {View} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';

import Config from '../../../Config';
import React from 'react';
import {transitionalEventStyle} from './style/transitionalEventTable.style';
import routeLink from '../../../constant/routeLink';
import { TextRender } from '@unthinkable/react-table';
import locationType from '../../../constant/locationType.js';

const renderLocation  = ({row})=>{
  let location = ""; 
  if(row?.locationType?.value === locationType.Home){
    location =  row?.locationType?.value;
  }
  else{
    location =  row?.location?.name;
  }
  return <TextRender value = {location}></TextRender>
}
export const TransitionalEventTable = props => {
  const {encounter} = props;
  return (
    <View style={transitionalEventStyle.containerView}>
      <TableHeader title={'Transitional Event'} />
      <BasicTable
        api={routeLink.getTransitionalEvent}
        fields={{
          event: 1,
          locationType:{
            value:1
          },
          location: {
            name:1
          },
          eventDate: 1,
        }}
        filter={{
          encounterId: encounter._id,
        }}
        sort={{eventDate: -1}}
        variant={'outline'}
        columns={[
          {
            header: 'Event',
            field: 'event',
            type: 'text',
          },
          {
            header: 'Location',
            render: renderLocation
          },
          {
            header: 'Event Date & Time',
            field: 'eventDate',
            type: 'date',
            formatOptions: {
              format: Config.dateTimeFormat,
            },
            width: 200,
          },
        ]}
        {...props}
      />
    </View>
  );
};