import Theme from 'src/theme/Theme';
import styled from 'styled-components';
export const ColumnTextRender = styled.text`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #141e2e;
`;
export const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

export const TaskCountContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const variantWiseTextColors = {
  ContactTask: Theme.colors.ACCENT7_HIGH,
  MedRecTask: Theme.colors.ACCENT8_HIGH,
  VisitTask: Theme.colors.ACCENT9_HIGH,
  NotesTask: Theme.colors.ACCENT10_HIGH,
};

const variantWiseBackgroundColor = {
  ContactTask: Theme.colors.ACCENT7_LOW,
  MedRecTask: Theme.colors.ACCENT8_LOW,
  VisitTask: Theme.colors.ACCENT9_LOW,
  NotesTask: Theme.colors.ACCENT10_LOW,
};
const variantWiseBorderColor = {
  ContactTask: Theme.colors.ACCENT7_MEDIUM,
  MedRecTask: Theme.colors.ACCENT8_MEDIUM,
  VisitTask: Theme.colors.ACCENT9_MEDIUM,
  NotesTask: Theme.colors.ACCENT10_MEDIUM,
}
export const TaskCountChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 16px;
  font-size: 12px;
  line-height: 18px;
  font-family: OpenSans-Medium;
  border:${({variant}) => `1px solid ${variantWiseBorderColor[variant]}`};
  background: ${({variant}) => variantWiseBackgroundColor[variant]};
  color: ${({variant}) => variantWiseTextColors[variant]};
`;
