import {View} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import {useNavigate} from 'react-router-dom';

import Config from '../../../Config';
import React from 'react';
import { patientDetailsWidgetStyle } from '../screens/style/PatientDetail.style';
import { TextRenderer } from '../../../components/formEditors/Editors';

export const TCMEncounters = props => {
  const renderStatus = ({row}) => {
    return <TextRenderer value={row.status}></TextRenderer>
  }
  const navigate = useNavigate();
  const {patient} = props;
  return (
    <View style={patientDetailsWidgetStyle}>
      <TableHeader title={'TCM Encounters'} />
      <BasicTable
        onRowPress={({row}) => {
          navigate(`/patient/details/encounter`, {
            state: {
              encounter: row,
              patient: patient,
            },
          });
        }}
        variant={'outline'}
        columns={[
          {
            header: 'Admission Date',
            field: 'admissionDate',
            type: 'date',
            formatOptions: {
              format: Config.dateFormat,
            },
          },
          {
            header: 'Location',
            field: 'currentLocation.name',
            type: 'text',
          },
          {
            header: 'Discharge Date',
            field: 'dischargeDate',
            type: 'date',
            formatOptions: {
              format: Config.dateFormat,
            },
          },
          {
            header: 'Status',
            render:renderStatus,
          }
        ]}
        {...props}
      />
    </View>
  );
};
