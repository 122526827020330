import {View} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import routeLink from '../../../constant/routeLink.js';

import React, {useEffect, useState} from 'react';
import {status} from '../../../constant/status';
import {patientDetailsWidgetStyle} from '../screens/style/PatientDetail.style';
import {HttpAuthService} from '../../../services';
import {RowHeading} from '../../../components/header/RowHeading';
import {generateCategoryWiseDiagnose} from '../../../services/patientDiagnose.service';
import {Icon} from '../../facilities/screens/FacilityTable.style';
import images from '../../../assets/images';

import {useEventHandler} from '@unthinkable/react-event-handler';
import {invokeEvents} from '../../../hooks/useInvoke';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';

export const OutPatientDiagnose = props => {
  const {patient} = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useDataFetchEvents({eventSourceId: 'outpatientDiagnose'}, () => {
    fetchPatientDiagnose();
  });
  const fetchPatientDiagnose = async () => {
    setLoading(true);
    const response = await HttpAuthService.get(
      routeLink.getOutPatientDiagnoses,
      {
        params: {
          filter: {
            patientId: patient._id,
            diagnoseApproved: true,
            status: status.active,
          },
          fields: {
            diagnose: {
              Name: 1,
              Code: 1,
              CodeSystemName: 1,
            },
          },
        },
      },
    );
    setData(generateCategoryWiseDiagnose(response.data));
    setLoading(false);
  };

  useEffect(() => {
    fetchPatientDiagnose();
  }, []);

  const renderTable = props => {
    const {row} = props;
    const {notify} = useEventHandler();

    if (!row) return;
    if (row?.name === 'Header') {
      return (
        <View style={patientDetailsWidgetStyle}>
          <BasicTable
            columns={[
              {
                header: 'Code',
                width: 150,
              },
              // {
              //   header: 'Code Name',
              // },
              {
                header: 'Diagnosis',
              },
            ]}></BasicTable>
        </View>
      );
    } else
      return (
        <View style={{flex: 1}}>
          <RowHeading
            title={row.name}
            actions={() => {
              return (
                <Icon
                  src={row.expanded ? images.showLess : images.showMore}
                  onClick={() => {
                    row.expanded = !row.expanded;
                    invokeEvents({
                      eventSourceId: ['outpatientDiagnose'],
                      notify,
                    });
                  }}
                />
              );
            }}
          />

          {row.expanded && (
            <BasicTable
              variant={'outline'}
              data={row.data}
              columns={[
                {
                  field: 'diagnose.Code',
                  type: 'text',
                  width: 150,
                },
                // {
                //   field: 'diagnose.CodeSystemName',
                //   type: 'text',
                // },
                {
                  field: 'diagnose.Name',
                  type: 'text',
                },
              ]}
              {...props}
            />
          )}
        </View>
      );
  };

  return (
    <View style={patientDetailsWidgetStyle}>
      <TableHeader title={'Outpatient Diagnoses'} />
      <BasicTable
        variant={'outline'}
        data={data}
        renderRow={renderTable}
        loading={loading}
        {...props}></BasicTable>
    </View>
  );
};
