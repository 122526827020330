import Theme from '../../../theme/Theme';
import styled from 'styled-components';
const BrandName = styled.text`
  font-weight: 700;
  line-height: 44px;
  font-size: 36px;
`;

const LoginMessage = styled.text`
  font-weight: 600;
  line-height: 28px;
  font-size: 18px;
`;
const ScreenContainer = styled.div`
  gap: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${Theme.colors.SECONDARY_UPPER_LOW};
  padding: 20px;
  gap: 36px;
  border-radius: 12px;
  box-shadow: #1018280d 0px 1px 2px 0px;
  width: 371px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 36px;
`;

const CenteredOverlay = styled.div`
  z-index: 99999;
  position: absolute;
  display: flex;
  align-self: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ffffffa0;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 7px;
  align-self: center;
`;

const FlexContainer = styled.div`
  display: flex;
  align-self: center;
  padding-right: 80px;
  padding-left: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${Theme.colors.BACKGROUND};
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
`;

const ClickableBox = styled.div`
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: ${Theme.colors.BACKGROUND};
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 8px;
`;

export {
  BrandName,
  LoginMessage,
  ScreenContainer,
  FormContainer,
  HeaderContainer,
  FooterContainer,
  LayoutContainer,
  CenteredOverlay,
  FlexContainer,
  ClickableBox,
};
