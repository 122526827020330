import {CNavItem, CSidebar, CSidebarNav} from '@coreui/react';
import {useTheme} from '@unthinkable/react-theme';
import React, {useState} from 'react';
import AppFooter from './AppFooter';
import {
  BrandContainer,
  BrandIcon,
  GroupItemContainer,
  GroupItemIcon,
  GroupItemText,
  SidebarNavContainer,
} from './AppSidebar.style';
import {AppSidebarNav} from './AppSidebarNav';
import {authorization, entity} from '../constant/authorization';
import {useAuth} from '../modules/auth/hooks/useAuth';
import {action} from '../constant/authorization';
import checkAuthorization from '../services/accessService';
// import {Button} from './button';
// import buttonType from 'src/constant/buttonType';

const GroupItem = item => {
  const {children, icon, name, isMenuSelected} = item;
  const [isParentHovered, setParentParentHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setParentParentHovered(true);
      }}
      onMouseLeave={() => {
        setParentParentHovered(false);
      }}>
      <GroupItemContainer>
        {icon && <GroupItemIcon src={icon} />}
        <GroupItemText>{name && name}</GroupItemText>
      </GroupItemContainer>
      <div hidden={!isParentHovered && !isMenuSelected}>{children}</div>
    </div>
  );
};

const AppSidebar = ({sidebarShow, setSidebarShow}) => {
  const {user} = useAuth();
  const items = [
    {
      component: CNavItem,
      name: 'Facilities',
      to: '/facilities-company',
    },
    {
      component: CNavItem,
      name: 'Practices',
      to: '/practices',
    },
    {
      component: CNavItem,
      name: 'User',
      to: '/users',
    },
    {
      component: CNavItem,
      name: 'Audit Logs',
      to: '/audit-logs',
    },
    {
      component: CNavItem,
      name: 'New Requests',
      to: '/new-requests',
    },
    {
      component: CNavItem,
      name: 'Cron Settings',
      to: '/cron-setting',
    },
  ];

  // for render menu on basis of permission
  const itemsToShow = items.filter(item => {
    return authorization[user.userType.name]?.[item.name]?.includes(
      action.view,
    );
  });

  const {
    Buildings,
    BuildingsSelected,
    Home,
    HomeSelected,
    Patients,
    PatientsSelected,
    Admin,
    AdminSelected,
    chatSmile,
    chatSmileSelected,
    PatientChat,
    PatientChatSelected,
    Tasks,
    TasksSelected,
    FullBrandLogo,
  } = useTheme('icons');

  const _nav = [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      icon: Home,
      selectedIcon: HomeSelected,
    },
    {
      component: CNavItem,
      name: 'Facilities',
      to: '/facility-stats',
      icon: Buildings,
      selectedIcon: BuildingsSelected,
    },
    {
      component: CNavItem,
      name: 'Patients',
      to: '/patient',
      icon: Patients,
      selectedIcon: PatientsSelected,
    },
    {
      component: CNavItem,
      name: 'Chat',
      to: '/groups',
      icon: chatSmile,
      selectedIcon: chatSmileSelected,
    },
    {
      component: CNavItem,
      name: 'Patient Chat',
      to: '/patientgroups',
      icon: PatientChat,
      selectedIcon: PatientChatSelected,
    },
    {
      component: CNavItem,
      name: 'Tasks',
      to: '/tasks',
      icon: Tasks,
      selectedIcon: TasksSelected,
    },
    {
      component: GroupItem,
      name: 'Admin',
      to: '/admin/',
      icon: Admin,
      selectedIcon: AdminSelected,
      items: itemsToShow,
    },
  ];

  const _navToShow = _nav.filter(item => {
    if (
      !user?.chatAuthKey &&
      (item.name === 'Chat' || item.name === 'Patient Chat')
    ) {
      return false;
    }
    if (item.name !== entity.patient) {
      return true;
    }
    return (
      checkAuthorization(user, item.name, action.viewAll) ||
      checkAuthorization(user, item.name, action.viewOwn)
    );
  });

  return (
    <CSidebar
      position="sticky"
      visible={sidebarShow}
      onVisibleChange={visible => setSidebarShow(visible)}>
      <BrandContainer>
        <BrandIcon src={FullBrandLogo} />
      </BrandContainer>
      <CSidebarNav>
        <SidebarNavContainer>
          <AppSidebarNav items={_navToShow} setSidebarShow={setSidebarShow} />
        </SidebarNavContainer>
      </CSidebarNav>
      <AppFooter setSidebarShow={setSidebarShow} />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
