const toasterMessage = {
  resetPassword: {
    success: 'Password reset Successfully. Please login',
    invalidLink: 'Invalid link. Please provide a valid link',
  },
  forgetPassword: {
    success:
      'An email with instructions to reset your password was sent. Please check your inbox',
    failed:
      'No user with this email was found in our system. Please enter a valid email address',
  },
  setPassword: {
    admin: {
      success: 'Password reset Successfully. Please login',
    },
    notAdmin: {
      success:
        'Password set Successfully. Please Enable Your Two Factor Authentication',
    },
  },
  enableTwoFactor: {
    success: 'Two Factor Authentication Enabled Successfully',
    failure: 'Failed to enable Two Factor Authentication',
  },
  ChangeExistingPassword: {
    success: 'Password Changed Successfully.',
  },
  passwordMatch: {
    failure: 'Password does not match',
  },
  resendInvite: {
    success: 'Invite has been sent successfully',
    failure: 'Sorry Currently unable to resend Invite',
  },
  getMessage: {
    failure: 'Sorry Currently unable to get Message from server',
  },
  loadMore: {
    failure: 'Sorry Currently unable to load more message from server',
  },
  sendMessage: {
    failure: 'Sorry Currently unable to send Message',
  },
  netWork: {
    failure: 'Network Error',
  },
  getCountMessage: {
    failure: 'Sorry Currently unable to get message count from server',
  },
  VerifyOtp: {
    after2FA: 'Successfully Enabled Email Verification',
    afterLogin: 'Login Successfully',
  },
};

export default toasterMessage;
