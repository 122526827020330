import React from 'react';
import {useFormSubmit} from 'src/hooks';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../../modules/auth/hooks/useAuth';

export const AddGroupMember = props => {
  const {channelId, groupId} = useParams();
  const {user} = useAuth();
  const {onSubmit} = useFormSubmit({
    uri: routeLink.addGroupMember,
    eventSourceId: ['chatmember'],
  });
  return (
    <Form
      skipHeader={false}
      header={{title: 'Add Group Members'}}
      saveButtonText={'Send Request'}
      onSubmit={onSubmit}
      defaultValues={{groupId, channelId}}
      layoutFields={[
        {
          type: 'multiAutoComplete',
          field: 'users',
          label: 'Users',
          api: routeLink.addGroupMembersSuggestion,
          required: true,
          suggestionField: 'name',
          size: 'medium',
          fields: {PNChatMemberId: 1, name: 1},
          sort: {name: 1},
          filter: {
            groupId: groupId,
            practiceId: user?.practiceId,
            facilityId: user?.facilityId,
          },
        },
      ]}
      {...props}
    />
  );
};
