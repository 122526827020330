import {AddCareTeamMember} from './components/addCareTeamMember.modal';
import {EditPatient} from './screens/editPatient';
import {AddPatientDocuments} from './screens/AddPatientDocuments';
import {PatientDetails} from './screens/PatientDetails';
import {PatientTableCareTeam} from './screens/PatientTableCareTeam';
import {PatientTable} from './screens/PatientTable';
import {PatientEncounterDetails} from './screens/PatientEncounterDetail';
import {CompleteVisitTask} from './components/CompleteTCMVisit.modal';
import {AddContactAttemptsModal} from './components/AddContactAttempts.modal';
import {TcmNotesModal} from './components/TcmNotes.modal';
import {AddMedication} from './screens/AddMedication';
import {AddMedicineModal} from './components/addMedicine.modal';
import checkAuthorization from '../../services/accessService';
import {action, entity} from '../../constant/authorization';
import PatientChatList from './screens/PatientChatList';
import {EditOutPatientMedicationDetails} from './components/EditOutPatientMedicationDetails';

const stackRoutes = [
  {
    name: 'Patients',
    path: '/patient',
    component: PatientTable,
    visible: user => {
      return checkAuthorization(user, entity.patient, action.viewAll);
    },
  },
  {
    name: 'Patients',
    path: '/patient',
    component: PatientTableCareTeam,
    visible: user => {
      return checkAuthorization(user, entity.patient, action.viewOwn);
    },
  },
  {
    name: 'Patients Group',
    path: '/patientgroups',
    component: PatientChatList,
    visible: user => {
      return (
        user?.chatAuthKey &&
        (checkAuthorization(user, entity.patient, action.viewAll) ||
          checkAuthorization(user, entity.patient, action.viewOwn))
      );
    },
  },
  {
    name: 'Patient Details',
    path: '/patient/details',
    component: PatientDetails,
    children: [
      {
        name: 'Add Care Team Member',
        path: '/patient/details/:PatientId/add-care-team-member',
        component: AddCareTeamMember,
        screenOptions: {
          modalSize: 'large',
        },
      },
      {
        name: 'Add Patient Documents',
        path: '/patient/details/add-documents',
        component: AddPatientDocuments,
        screenOptions: {
          modalSize: 'medium',
        },
      },
      {
        name: 'Edit Out Patient Medication Details',
        path: '/patient/details/edit-out-patient-medication-details',
        component: EditOutPatientMedicationDetails,
        screenOptions: {
          modalSize: 'medium',
        },
      },
    ],
    visible: user => {
      return (
        checkAuthorization(user, entity.patient, action.viewAll) ||
        checkAuthorization(user, entity.patient, action.viewOwn)
      );
    },
  },
  {
    name: 'Edit Patient',
    path: '/patient/details/edit/:PatientId',
    component: EditPatient,
    visible: user => {
      return checkAuthorization(user, entity.patient, action.edit);
    },
  },
  {
    name: 'View Patient Encounter',
    path: '/patient/details/encounter',
    component: PatientEncounterDetails,
    children: [
      {
        name: 'Complete Visit',
        path: '/patient/details/encounter/complete-visit/:taskId',
        component: CompleteVisitTask,
      },
      {
        name: 'Add Contact Attempts',
        path: '/patient/details/encounter/:EncounterId/:TaskId/add-contact-attempts',
        component: AddContactAttemptsModal,
        screenOptions: {
          modalSize: 'large',
        },
      },
      {
        name: 'TCM Notes',
        path: '/patient/details/encounter/tcm-notes',
        component: TcmNotesModal,
        screenOptions: {
          modalSize: 'xlarge',
        },
      },
    ],
    visible: user => {
      return (
        checkAuthorization(user, entity.patient, action.viewAll) ||
        checkAuthorization(user, entity.patient, action.viewOwn)
      );
    },
  },
  {
    name: 'Add Medication',
    path: '/patient/details/encounter/add-medication',
    component: AddMedication,
    children: [
      {
        name: 'Add Medicine',
        path: '/patient/details/encounter/add-medication/medicine',
        component: AddMedicineModal,
      },
    ],
  },
];

const modalRoutes = [];

export default {
  stack: stackRoutes,
  modal: modalRoutes,
};
