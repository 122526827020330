import React, {useEffect, useState} from 'react';
import {View} from '@unthinkable/react-core-components';
import {useLocation} from 'react-router-dom';
import {
  PatientHeader,
  TransitionalEventTable,
  HospitalizationDiagnose,
  HospitalizationMedication,
} from '../components/index';
import {patientDetailStyles} from './style';
import {HttpAuthService} from '../../../services';
import {routeLink} from '../../../constant';

import {ContactAttempts} from '../components/ContactAttemptsTable';
import {status} from '../../../constant/status';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {TableHeader} from '../../../components/header/TableHeader';

export const PatientEncounterDetails = () => {
  const {
    state: {patient, encounter},
  } = useLocation();
  const [tcmNotesStatus, setTCMNotesStatus] = useState(false);
  const [visitTaskDetail, setVisitTaskDetail] = useState(null);
  const [medicationTaskDetail, setMedicationTaskDetail] = useState(null);
  const [ notesTaskId , setNotesTaskId] = useState(null);
  const {user} = useAuth();
  let isTcmMedicationButtonVisiable = false;
  let isTcmVisitTaskButtonVisiable = false;
  if (
    checkAuthorization(user, entity.tcmTask, action.complete) &&
    user?.practiceId?._id === patient?.primaryPractice?._id &&
    medicationTaskDetail &&
    medicationTaskDetail?.status !== status.complete
  ) {
    isTcmMedicationButtonVisiable = true;
  }
  if (
    checkAuthorization(user, entity.tcmTask, action.complete) &&
    user?.practiceId?._id === patient?.primaryPractice?._id &&
    visitTaskDetail &&
    visitTaskDetail?.status !== status.complete
  ) {
    isTcmVisitTaskButtonVisiable = true;
  }
  const tcmVisitTaskId = encounter?.taskInfo?.find(
    task => task.type === 'VisitTask',
  )?.taskId;

  const tcmMedicationTask = encounter?.taskInfo?.find(
    task => task.type === 'MedicationTask',
  );
  const getVisitTask = async () => {
    const response = await HttpAuthService.get(routeLink.getTCMTask, {
      params: {
        filter: JSON.stringify({
          taskId: tcmVisitTaskId,
          encounterId: encounter._id,
        }),
        only: true,
      },
    });
    setVisitTaskDetail(response?.data);
  };

  const getMedicationTask = async () => {
    const tcmMedicationTaskResponse = await HttpAuthService.get(
      routeLink.getTCMTask,
      {
        params: {
          filter: JSON.stringify({
            taskId: tcmMedicationTask.taskId,
            encounterId: encounter._id,
          }),
          only: true,
        },
      },
    );
    setMedicationTaskDetail(tcmMedicationTaskResponse?.data);
  };

  const taskId = encounter?.taskInfo?.find(
    el => el.type === 'ContactTask',
  )?.taskId;

  useEffect(() => {
    if (!tcmVisitTaskId) return;
    getVisitTask();
  }, []);

  useEffect(() => {
    if (!tcmMedicationTask || tcmMedicationTask.status === status.complete)
      return;
    getMedicationTask();
  }, []);

  useDataFetchEvents({eventSourceId: 'visitAttempt'}, () => {
    getVisitTask();
  });

  useEffect(() => {
    getTCMNotesStatus();
  }, []);

  useDataFetchEvents({eventSourceId: 'tcm-notes-status'}, () => {
    getTCMNotesStatus();
  });

  useDataFetchEvents({eventSourceId: 'addMedication'}, () => {
    getMedicationTask();
  });

  const getTCMNotesStatus = async () => {
    const response = await HttpAuthService.get(`${routeLink.getTCMTask}`, {
      params: {
        filter: JSON.stringify({
          encounterId: encounter._id,
          type: 'NotesTask',
        }),
        fields: {
          status: 1,
          successStatus: 1,
        },
      },
    });
    setNotesTaskId(response?.data[0]?._id);
    if (response.data[0]?.status === status.complete) {
      setTCMNotesStatus(false);
    } else {
      setTCMNotesStatus(true);
    }
  };
  return (
    <View style={patientDetailStyles.styles.container}>
      <PatientHeader
        patient={patient}
        visitTaskId={tcmVisitTaskId}
        notesTaskId = {notesTaskId}
        encounter={encounter}
        isTcmVisitTaskButtonVisiable={isTcmVisitTaskButtonVisiable}
        isTaskAvailable={encounter?.taskInfo?.length ? true : false}
        medicationTask={tcmMedicationTask}
        isTcmMedicationButtonVisiable={isTcmMedicationButtonVisiable}
        isGenerateNotesButtonVisible={ checkAuthorization(user, entity.tcmTask, action.complete) && tcmNotesStatus}
      />
      {encounter.diagnoseReason && (
        <View style={patientDetailStyles.styles.row}>
          <TableHeader title={'Hospitalization Reason:'} />
          {encounter.diagnoseReason}
        </View>
      )}
      <View style={patientDetailStyles.styles.row}>
        <TransitionalEventTable patient={patient} encounter={encounter} />
        {taskId && (
          <ContactAttempts
            patient={patient}
            encounter={encounter}
            taskId={taskId}></ContactAttempts>
        )}
      </View>
      <View style={patientDetailStyles.styles.row}>
        <HospitalizationDiagnose encounter={encounter} />
        <HospitalizationMedication
          encounter={encounter}
          patient={patient}
          isTcmMedicationButtonVisiable={isTcmMedicationButtonVisiable}
          entity={'ENCOUNTER'}
          medicationId={null}
        />
      </View>
    </View>
  );
};
