import {View} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import routeLink from '../../../constant/routeLink.js';

import React from 'react';
// import {combineText} from '../../../utils/commonMethod';
// import {TextRenderer} from '@unthinkable/react-text-input';
import {patientDetailsWidgetStyle} from '../screens/style/PatientDetail.style';
import {status} from '../../../constant/status';
import {AutoCompleteFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
// import images from '../../../assets/images';
// import {useNavigate} from 'react-router-dom';

// const RenderEdit = () => {
//   return <img src={images.edit} width={20} height={20} />;
// };
export const OutPatientMedication = props => {
  const {patient} = props;
  // const navigate = useNavigate();
  const filterProps = useFilter({
    params: {
      status: status.active,
    },
  });
  const {onChangeFilter, filterValues} = filterProps;
  const {params} = filterValues;
  const filter = {
    patientId: patient._id,
  };
  if (params.status) {
    filterValues.filter = {
      ...filterValues.filter,
      ...filter,
      status: params.status,
    };
  } else {
    delete filterValues?.filter?.status;
  }
  return (
    <View style={patientDetailsWidgetStyle}>
      <TableHeader
        title={'Outpatient Medication'}
        actions={[
          <AutoCompleteFilter
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
            field={'status'}
            options={[status.active, status.inactive]}
            key={'statusFilter'}
            placeholder={'Status'}
            asParam={true}
          />,
        ]}
      />
      <BasicTable
        params={{...params}}
        variant={'outline'}
        eventSourceId="outpatient-medications"
        api={routeLink.getOutPatientMedication}
        fields={{
          medicine: {
            Name: 1,
          },
          Dose: 1,
          Frequency: 1,
          status: 1,
        }}
        filter={filterValues.filter}
        columns={[
          {
            header: 'Medication Name',
            field: 'medicine.Name',
            type: 'text',
          },
          // {
          //   header: 'Dose',
          //   render: ({row, ...rest}) => {
          //     return (
          //       <TextRenderer
          //         {...rest}
          //         value={
          //           row?.Frequency?.Unit &&
          //           combineText(row?.Dose?.Quantity, row?.Dose?.Units)
          //         }
          //       />
          //     );
          //   },
          // },
          // {
          //   header: 'Frequency',
          //   render: ({row, ...rest}) => {
          //     return (
          //       <TextRenderer
          //         {...rest}
          //         value={
          //           row?.Dose?.Units &&
          //           combineText(row?.Frequency?.Period, row?.Frequency?.Unit)
          //         }
          //       />
          //     );
          //   },
          // },
          {
            header: 'Status',
            field: 'status',
            type: 'string',
            width: '90px',
          },
          // {
          //   render: RenderEdit,
          //   onPress: ({row}) => {
          //     navigate('/patient/details/edit-out-patient-medication-details', {
          //       state: {
          //         id: row._id,
          //         titleName:row.medicine.Name,
          //         patient,
          //       },
          //     });
          //   },
          //   width: 50,
          // },
        ]}
        {...props}
      />
    </View>
  );
};
