import styled from 'styled-components';
import theme from '../theme/Theme';
const GroupItemContainer = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  flex-direction: row;
  display: flex;
`;

const GroupItemText = styled.div`
  padding-left: 8px;
`;

const SidebarNavContainer = styled.div`
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: ${theme.colors.NEUTRAL_HIGH};
  flex: 1;
  margin-right: -18px;
`;

const BrandContainer = styled.div`
  text-align: center;
  justify-content: center;
  background-color: ${theme.colors.NEUTRAL_HIGH};
`;
const BrandText = styled.text`
  text-align: center;
`;

const GroupItemIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const BrandIcon = styled.img`
  width: 100%;
  padding: 4px;
  background-color: ${theme.colors.ICON_BACKGROUND_COLOR};
`;
const SideBarOpenCloseIcon = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 2px 6px;
  margin: 12px -15px 0 -6px;
  position: ${props => (props.isOpen ? 'sticky' : 'absolute')};
  left: 0;
  top: 0;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  background-color: ${theme.colors.ICON_BACKGROUND_COLOR};
`;

export {
  BrandContainer,
  BrandIcon,
  BrandText,
  GroupItemContainer,
  GroupItemIcon,
  GroupItemText,
  SidebarNavContainer,
  SideBarOpenCloseIcon,
};
