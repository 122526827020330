import ChatMainScreen from './screens/ChatMainScreen';
import GroupsLists from './screens/GroupsLists';
import GroupMembersList from './screens/GroupMembersList';
import {AddGroupMember} from './components/AddGroupMember';
const stackRoutes = [
  {
    name: 'Chat',
    path: '/groups/chat/:channelId/:groupId',
    component: ChatMainScreen,
    visible: user => user?.chatAuthKey,
  },
  {
    name: 'Chat',
    path: '/patient/chat/:channelId/:groupId',
    component: ChatMainScreen,
    visible: user => user?.chatAuthKey,
  },
  {
    name: 'Chat',
    path: '/patientgroups/chat/:channelId/:groupId',
    component: ChatMainScreen,
    visible: user => user?.chatAuthKey,
  },
  {
    name: 'Groups',
    path: '/groups',
    component: GroupsLists,
    visible: user => user?.chatAuthKey,
  },
  {
    name: 'Group Members',
    path: '/groups/chat/:channelId/:groupId/GroupMembersList',
    component: GroupMembersList,
    visible: user => user?.chatAuthKey,
    children: [
      {
        name: 'Add Member',
        path: '/groups/chat/:channelId/:groupId/GroupMembersList/AddGroupMember',
        component: AddGroupMember,
        visible: user => user?.chatAuthKey,
        screenOptions: {
          modalSize: 'small',
        },
      },
    ],
  },
  {
    name: 'Group Members',
    path: '/patient/chat/:channelId/:groupId/GroupMembersList',
    component: GroupMembersList,
    visible: user => user?.chatAuthKey,
    children: [
      {
        name: 'Add Member',
        path: '/patient/chat/:channelId/:groupId/GroupMembersList/AddGroupMember',
        component: AddGroupMember,
        screenOptions: {
          modalSize: 'small',
        },
      },
    ],
  },
  {
    name: 'Group Members',
    path: '/patientgroups/chat/:channelId/:groupId/GroupMembersList',
    component: GroupMembersList,
    visible: user => user?.chatAuthKey,
  },
];

const modalRoutes = [];

export default {
  stack: stackRoutes,
  modal: modalRoutes,
};
