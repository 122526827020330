import icons from '../assets/images';

const breakpoints = {
  sm: 480,
  md: 768,
};

const colors = {
  MODAL_BACKGROUND: 'rgba(0,0,0,0.1)',

  BACKGROUND: '#FFFFFF',
  SURFACE1: '#FCFCFC',
  SURFACE2: '#F7F7F7',
  SURFACE3: '#FAFAFA',
  OUTLINE: '#EEEEED',
  UPPER_OUTLINE: '#D6D6D6',

  NEUTRAL_HIGH: '#141E2E',
  BUTTON_NEUTRAL_HIGH: '#324C74',
  NEUTRAL_MEDIUM: '#7D7D82',
  NEUTRAL_LOW: '#A3A3A3',

  BRAND_HIGH: '#103300',
  BRAND_UPPER_MEDIUM: '#7DCD28',
  BRAND_MEDIUM: '#B2EE7C',
  BRAND_UPPER_LOW: '#E4FDD8',
  BRAND_LOW: '#F3FEF0',

  SECONDARY_HIGH: '#3562FF',
  SECONDARY_UPPER_MEDIUM: '#4770FF',
  SECONDARY_MEDIUM: '#E3E8FC',
  SECONDARY_UPPER_LOW: '#D6DBE3',
  SECONDARY_LOW: '#F1F4FE',

  ERROR_HIGH: '#FF3B30',
  ERROR_MEDIUM: '#FFE2E0',
  ERROR_LOW: '#FFF1F0',

  SUCCESS_HIGH: '#34C759',
  SUCCESS_MEDIUM: '#D8FDE2',
  SUCCESS_LOW: '#F0FFF4',

  WARNING_HIGH: '#FF9500',
  WARNING_MEDIUM: '#FFF2E0',
  WARNING_LOW: '#FFF9F0',

  INFORMATION_HIGH: '#007AFF',
  INFORMATION_MEDIUM: '#E0EFFF',
  INFORMATION_LOW: '#F0F7FF',

  ACCENT1_HIGH: '#32ADE6',
  ACCENT1_MEDIUM: '#E0F5FF',
  ACCENT1_LOW: '#F0FAFF',

  ACCENT2_HIGH: '#FF2D96',
  ACCENT2_MEDIUM: '#FFE0F0',
  ACCENT2_LOW: '#FFF0F7',

  ACCENT3_HIGH: '#00C7BE',
  ACCENT3_MEDIUM: '#E0FFFE',
  ACCENT3_LOW: '#F0FFFE',

  ACCENT4_HIGH: '#FF7A00',
  ACCENT4_MEDIUM: '#FFEFE0',
  ACCENT4_LOW: '#FFF7F0',

  ACCENT5_HIGH: '#FFCC00',
  ACCENT5_MEDIUM: '#FFF9E0',
  ACCENT5_LOW: '#FFFCF0',

  ACCENT6_HIGH: '#AF52DE',
  ACCENT6_MEDIUM: '#F5E0FF',
  ACCENT6_LOW: '#FAF0FF',

  ACCENT7_HIGH: '#5925DC',
  ACCENT7_MEDIUM: '#E0D0FF',
  ACCENT7_LOW: '#D9D6FE',

  ACCENT8_HIGH: '#026AA2',
  ACCENT8_MEDIUM: '#B9E6FE',
  ACCENT8_LOW: '#E0F5FF',

  ACCENT9_HIGH: '#9F1AB1',
  ACCENT9_MEDIUM: '#F6D0FE',
  ACCENT9_LOW: '#F6E9FF',

  ACCENT10_HIGH: '#008953',
  ACCENT10_MEDIUM: '#D0F5A1',
  ACCENT10_LOW: '#E0F8F2',

  PATIENT_NAV_HIGH: '#fee9ac',
  PATIENT_NAV_LOW: '#fffaea',

  SIDEBAR_BACKGROUND_HIGH: '#141E2E',
  SIDEBAR_BACKGROUND_MEDIUM: '#8494ac',
  SIDEBAR_BACKGROUND_LOW: '#324c74',
  ICON_BACKGROUND_COLOR: '#d1cfcf',

  CANCEL_BUTTON_HOVER_BACKGROUND_COLOR: '#EBEDF1',
  SAVE_BUTTON_HOVER_BACKGROUND_COLOR: '#1B1A55',
  GREEN_STATUS_COLOR: '#00FF00',
  RED_STATUS_COLOR: '#F04438',

  GREEN_TCM_FORE_COLOR: '#067647',
  GREEN_TCM_BACKGROUND_COLOR: '#ECFDF3',
  GREEN_TCM_BORDER_COLOR: '#ABEFC6',

  YELLOW_TCM_FORE_COLOR: '#B54708',
  YELLOW_TCM_BACKGROUND_COLOR: '#FFF9E0',
  YELLOW_TCM_BORDER_COLOR: '#FEDF89',

  RED_TCM_FORE_COLOR: '#B42318',
  RED_TCM_BACKGROUND_COLOR: '#FEF3F2',
  RED_TCM_BORDER_COLOR: '#FECDCA',

  GRAY_TCM_FORE_COLOR: '#344054',
  GRAY_TCM_BACKGROUND_COLOR: '#F9FAFB',
  GRAY_TCM_BORDER_COLOR: '#EAECF0',

  DUE_CONTACT_TASK_COLOR: '#CA8504',
  OVERDUE_CONTACT_TASK_COLOR: '#854A0E',
  DUE_MEDREC_TASK_COLOR: '#BA24D5',
  OVERDUE_MEDREC_TASK_COLOR: '#821890',
  DUE_VISIT_TASK_COLOR: '#4CA30D',
  OVERDUE_VISIT_TASK_COLOR: '#326212',
  DUE_NOTES_TASK_COLOR: '#5B99C2',
  OVERDUE_NOTES_TASK_COLOR: '#2E4B6B',
};

export const fonts = {
  TITLE: {
    fontSize: 17,
    fontFamily: 'OpenSans-Bold',
    lineHeight: '20px',
  },
  HEADING1: {
    fontSize: 16,
    fontFamily: 'OpenSans-SemiBold',
    lineHeight: '20px',
  },
  HEADING2: {
    fontSize: 16,
    fontFamily: 'OpenSans-SemiBold',
    lineHeight: '20px',
  },
  HEADING3: {
    fontSize: 30,
    fontFamily: 'OpenSans-Bold',
    lineHeight: '38px',
  },
  HEADING4: {
    fontSize: 18,
    fontFamily: 'OpenSans-Medium',
    lineHeight: '18px',
  },
  SECTION_HEADING1: {
    fontSize: 15,
    fontFamily: 'OpenSans-SemiBold',
    lineHeight: '20px',
  },
  SECTION_HEADING2: {
    fontSize: 15,
    fontFamily: 'OpenSans-Medium',
  },
  BODY1: {
    fontSize: 14,
    fontFamily: 'OpenSans-SemiBold',
    lineHeight: '18px',
  },
  BODY2: {
    fontSize: 14,
    fontFamily: 'OpenSans-Medium',
    lineHeight: '18px',
  },
  BODY3: {
    fontSize: 14,
    fontFamily: 'OpenSans-Regular',
    lineHeight: '20px',
  },
  ACTION: {
    fontSize: 14,
    fontFamily: 'OpenSans-Medium',
    lineHeight: '18px',
  },
  SIDE_NAVIGATION: {
    fontSize: 13,
    fontFamily: 'OpenSans-Medium',
    lineHeight: '18px',
  },
  CAPTION: {
    fontSize: 13,
    fontFamily: 'OpenSans-Italic',
    lineHeight: '16px',
  },
  CAPTION_LARGE: {
    fontSize: 12,
    fontFamily: 'OpenSans-Medium',
    lineHeight: '16px',
  },
  CAPTION_SMALL: {
    fontSize: 12,
    fontFamily: 'OpenSans-Regular',
    lineHeight: '16px',
  },
  FOOTNOTE: {
    fontSize: 10,
    fontFamily: 'OpenSans-Medium',
    lineHeight: '10px',
  },
  FOOTNOTE_SMALL: {
    fontSize: 10,
    fontFamily: 'OpenSans-Medium',
    lineHeight: '12px',
  },
  HEADER_TEXT: {
    fontSize: 12,
    fontFamily: 'OpenSans-Bold',
    lineHeight: '18px',
  },
};

const shadow = {
  shadow1: {boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)'},
  shadow2: {boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.08)'},
  shadow3: {boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.06)'},
  shadow4: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  },
  shadow5: {
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.06)',
  },
  shadow6: {
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
};

const radius = {
  '2xs': 1,
  xs: 2,
  sm: 3,
  md: 4,
  lg: 6,
  xl: 8,
  '2xl': 12,
  '3xl': 16,
  '4xl': 20,
  '5xl': 24,
};

const spacing = {
  '2xs': 2,
  xs: 4,
  sm: 6,
  md: 8,
  lg: 10,
  xl: 12,
  '2xl': 16,
  '3xl': 20,
  '4xl': 24,
  '5xl': 30,
  '6xl': 32,
  '7xl': 36,
};

export default {
  breakpoints,
  colors,
  icons,
  fonts,
  radius,
  spacing,
  shadow,
};
