import Pubnub from 'pubnub';
import Config from '../../Config';
export const onMFASuccess = async ({
  user,
  navigate,
  setAuth,
  toast,
  message,
  toastType,
}) => {
  const pubnub = new Pubnub({
    publishKey: Config.PUB_NUB_PUBLISH_KEY,
    subscribeKey: Config.PUB_NUB_SUBSCRIBE_KEY,
    userId: user.PNChatMemberId,
    authKey: user.chatAuthKey,
    ssl: true,
  });
  user.pubnubInstance = pubnub;
  setAuth({user: user});
  toast({
    message,
    type: toastType,
  });
  navigate('/');
};
