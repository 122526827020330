import styled from 'styled-components';
import theme from '../../../theme/Theme';
import {Row, Text} from '@unthinkable/react-core-components';

const variantWiseBorderColors = {
  normal: theme.colors.NEUTRAL_HIGH,
  warning: theme.colors.PATIENT_NAV_HIGH,
  success: theme.colors.SUCCESS_HIGH,
  alarm: theme.colors.RED_TCM_FORE_COLOR
};

const variantWiseBackgroundColor = {
  normal: theme.colors.BACKGROUND,
  warning: theme.colors.PATIENT_NAV_LOW,
  success: theme.colors.SUCCESS_LOW,
  alarm: theme.colors.RED_TCM_BACKGROUND_COLOR
};

export const StyledSeparator = styled.div`
  height: 21.21px;
  width: 21.21px;
  border-bottom: 1px solid
    ${({variant = 'normal'}) => variantWiseBorderColors[variant]};
  border-right: 1px solid
    ${({variant = 'normal'}) => variantWiseBorderColors[variant]};
  transform: rotate(-45deg);
  margin-left: -10.4px;
  background-color: ${({variant = 'normal'}) =>
    variantWiseBackgroundColor[variant]};
  overflow: hidden;
  margin-top: 4.45px;
`;

export const LeftNavBoxTitle = styled.div`
  padding: 4px 15px;
  cursor: pointer;
  background-color: ${({variant}) => variantWiseBackgroundColor[variant]};
  border: 1px solid ${({variant}) => variantWiseBorderColors[variant]};
  border-right: none;
  border-radius: 8px 0px 0px 8px;
  padding-right: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
`;

export const MiddleNavBoxTitle = styled.div`
  padding: 4px 15px;
  cursor: pointer;
  background-color: ${({variant}) => variantWiseBackgroundColor[variant]};
  border: 1px solid ${({variant}) => variantWiseBorderColors[variant]};
  border-left: none;
  border-right: none;
  padding-left: 24px;
  margin-left: -12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
`;

export const RightNavBoxTitle = styled.div`
  padding: 4px 15px;
  cursor: pointer;
  background-color: ${({variant}) => variantWiseBackgroundColor[variant]};
  border: 1px solid ${({variant}) => variantWiseBorderColors[variant]};
  border-radius: 0px 8px 8px 0px;
  border-left: none;
  padding-left: 24px;
  margin-left: -12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
`;

export const NavBoxMessage = styled(Text)`
  ${() => ({
    numberOfLines: 1,
    overflow: 'hidden',
    ...theme.fonts.CAPTION_LARGE,
  })}
`;

export const MainContainer = styled(Row)`
  border-radius: 8px;
  flex-direction: row-reverse;
  align-self: flex-start;
`;
