import React from 'react';
import {useNavigate} from 'react-router-dom';
import Config from '../../../Config';
import {TableHeader} from '../../../components/header/TableHeader';
import Table from '../../../components/table/Table';
import {TextRender} from '@unthinkable/react-table';
import {ColumnTextRender} from '../../../modules/tasks/styles/TaskTable.style';
import moment from 'moment';
import {routeLink} from '../../../constant';
import {
  TASK_STATUS,
  TASK_TYPE,
  TASK_TYPE_TO_SHOW,
} from '../../../constant/taskType';
import {STATUS} from '../../user/constants/UserConstants';
import {isAdmin} from '../../../utils/checkUserType';
import {getPatientsFullName} from '../../../utils/commonMethod';

const renderLocation = ({row}) => {
  const location = row?.patientId?.currentLocation
    ? row?.patientId?.currentLocation?.name
    : row?.patientId?.currentLocationType?.value;
  return <TextRender value={location} />;
};

const getDifference = tcmDueDate => {
  let hours = (new Date(tcmDueDate) - new Date()) / 36e5; // 36e5 is 1 hour in milliseconds
  if (hours <= 0) {
    return 'Overdue';
  } else if (hours > 0 && hours < 24) {
    return 'Red';
  } else if (hours >= 24 && hours < 72) {
    return 'Yellow';
  } else if (hours >= 72) {
    return 'Gray';
  }
};

const tcmDueDate = ({row}) => {
  let dueDate = row?.tcmDueDate;
  if (!dueDate) {
    return;
  }
  if (row?.nextTcmDueDate) {
    if (new Date(row?.tcmDueDate) < new Date()) {
      dueDate = row?.nextTcmDueDate;
    }
  }
  return (
    <ColumnTextRender type={getDifference(dueDate)}>
      {moment(dueDate).utc().format(`${Config.dateFormat}`)}
    </ColumnTextRender>
  );
};

const renderTaskType = ({row}) => {
  return <TextRender value={TASK_TYPE_TO_SHOW[row?.type]} />;
};

export const MyTaskTable = props => {
  const {taskFilter} = props;
  const navigate = useNavigate();
  let filter = {};
  if (!isAdmin()) {
    filter = {
      type: {$ne: TASK_TYPE.InitialForm1823},
    };
  }
  return (
    <Table
      onRowPress={({row}) => {
        navigate('/patient/details', {
          state: {
            patient: row.patientId,
            titleName: getPatientsFullName({
              FirstName: row.patientId.FirstName,
              MiddleName: row.patientId.MiddleName,
              LastName: row.patientId.LastName,
              Render: false,
            }),
          },
        });
      }}
      perPage={Config.defaultListLimit}
      noDataText={'No task found'}
      renderHeader={() => <TableHeader title={'Outstanding TCM Tasks'} />}
      sort={{isDateBounded: -1, onGoingTcmDueDate: 1, _id: 1}}
      api={`${routeLink.getTCMTask}`}
      eventSourceId="tcmtasks"
      addOnFilter={JSON.stringify({
        status: {$ne: TASK_STATUS.Complete},
        ...taskFilter,
        ...filter,
        'patientId.status': {$eq: STATUS.ACTIVE},
      })}
      limit={Config.defaultListLimit}
      fields={{
        _id: 1,
        patientId: {
          _id: 1,
          FirstName: 1,
          MiddleName: 1,
          LastName: 1,
          FullName: 1,
          DOB: 1,
          groupId: {
            _id: 1,
            name: 1,
            PNGroupId: 1,
          },
          currentLocationType: {
            value: 1,
          },
          status: 1,
          residenceType: {
            value: 1,
          },
          currentLocation: {
            name: 1,
          },
          residence: 1,
          primaryPractice: {
            _id: 1,
          },
          latestEncounter: {
            taskInfo: {
              _id: 1,
            },
          },
        },
        owners: 1,
        type: 1,
        tcmDueDate: 1,
        nextTcmDueDate: 1,
        onGoingTcmDueDate: 1,
        isDateBounded: 1,
        encounterId: {
          _id: 1,
          dischargeDate: 1,
          taskInfo: 1,
        },
        taskId: 1,
      }}
      columns={[
        {
          header: 'Patient name',
          type: 'text',
          render: ({row}) => {
            const {FirstName, LastName, MiddleName} = row.patientId;
            return getPatientsFullName({FirstName, LastName, MiddleName});
          },
        },
        {
          header: 'Date Of Birth',
          field: 'patientId.DOB',
          type: 'date',
          formatOptions: {
            format: Config.dateFormat,
          },
        },
        {
          header: 'Task Type',
          render: renderTaskType,
        },
        {
          header: 'Location Of Patient',
          render: renderLocation,
        },
        {
          header: 'TCM Due Date',
          render: tcmDueDate,
        },
      ]}
      {...props}
    />
  );
};
