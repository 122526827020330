import tableReducer from './components/table/reducer';
import {
  chatReducer,
  unreadMessageCountReducer,
} from './modules/chat/chatRedux/reducer';
import {combineReducers} from 'redux';

export default combineReducers({
  table: tableReducer,
  chat: chatReducer,
  chatMessageCount: unreadMessageCountReducer,
});
