import React from 'react';
import {useParams} from 'react-router-dom';
import {useFormSubmit} from 'src/hooks';
import {Form} from '../../../components/form';
import {status} from 'src/constant/status';
import {USER_TYPE} from 'src/constant/userType';
import {routeLink} from 'src/constant';
import HttpAuth from 'src/services/HttpAuthService';
import {STATE_CODE, ZIPCODE_LEN} from 'src/constant/stateCode';
import {
  facilityShortNameValidation,
  nameValidation,
  phoneAndFaxValidation,
} from '../../../utils/validations/commonValidations';

export const AddFacility = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/v1/facilities',
    eventSourceId: 'facilities',
  });
  return (
    <Form
      saveButtonText={props.mode === 'edit' ? 'Save' : 'Create Facility'}
      onSubmit={onSubmit}
      computations={{
        getaddress: {
          compute: async value => {
            try {
              const {data} = await HttpAuth.get(routeLink.getAddressesFromZip, {
                params: {
                  zipCode: value.zipCode,
                },
              });
              return {
                city: data.city,
                state: STATE_CODE[data.state],
              };
            } catch (error) {
              return {
                city: '',
                state: '',
              };
            }
          },
          multi: true,
          dependencies: ['zipCode'],
          condition: ({zipCode}) => {
            return zipCode.length == ZIPCODE_LEN;
          },
        },
      }}
      layoutFields={[
        {
          label: 'Facility Info',
          fields: [
            {
              type: 'text',
              label: 'Name',
              field: 'name',
              required: true,
              validate: nameValidation,
            },
            {
              type: 'text',
              label: 'Short',
              field: 'shortName',
              validate: facilityShortNameValidation,
              required: true,
              size: 'medium',
            },
            {
              type: 'autoComplete',
              field: 'type',
              label: 'Facility Type',
              required: true,
              options: [
                'Assisted Living',
                'Hospital',
                'Independent Living',
                'Inpatient Psych',
                'Inpatient Rehab',
                'Skilled Nursing',
              ],
              size: 'medium',
            },
            {
              type: 'text',
              label: 'Street Address',
              field: 'streetAddress',
              required: true,
              size: 'large',
            },
            {
              type: 'text',
              label: 'Zip Code',
              field: 'zipCode',
              required: true,
              size: 'large',
            },
            {
              type: 'text',
              label: 'City',
              field: 'city',
              required: true,
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'text',
              label: 'State',
              field: 'state',
              required: true,
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'text',
              label: 'Phone No.',
              field: 'phoneNumber',
              validate: value => phoneAndFaxValidation(value, 'phone'),
              required: true,
              size: 'medium',
            },
            {
              type: 'text',
              label: 'Fax No.',
              validate: value => phoneAndFaxValidation(value, 'fax'),
              field: 'faxNumber',
              size: 'medium',
            },
            {
              type: 'autoComplete',
              field: 'isRedoxFacility',
              label: 'origin',
              size: 'medium',
              options: [
                {label: 'Elitecare Facility', value: false},
                {label: 'Redox Facility', value: true},
              ],
              key: 'origin',
              keyField: 'value',
              suggestionField: 'label',
              visible: props.mode === 'edit',
            },
          ],
        },
        {
          label: 'Facility Admins',
          fields: [
            {
              type: 'autoComplete',
              field: 'primaryAdmin',
              filter: JSON.stringify({
                status: status.active,
                facilityId: props?.api?.split('/').at(-1),
                'userType.name': USER_TYPE.FACILITY_ADMIN,
              }),
              label: 'Primary Admin',
              api: '/v1/users',
              required: false,
              suggestionField: 'name',
              secondarySuggestionField: 'email',
              size: 'medium',
              sort: {name: 1},
            },
          ],
          visible: props.mode === 'edit',
        },
        {
          label: 'Practices',
          fields: [
            {
              type: 'multiAutoComplete',
              field: 'practices',
              label: 'Practices',
              api: '/v1/practices',
              required: true,
              suggestionField: 'name',
              secondarySuggestionField: 'shortName',
              size: 'medium',
              sort: {name: 1},
              filter: ({values}) => {
                return JSON.stringify({
                  _id: {
                    $nin: [...(values?.practices || [])],
                  },
                  status: status.active,
                });
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditFacility = props => {
  const {facilityId} = useParams();
  return (
    <AddFacility
      {...props}
      header="Edit Facility"
      mode="edit"
      api={`/v1/facilities/${facilityId}`}
      fields={{
        name: 1,
        shortName: 1,
        phoneNumber: 1,
        faxNumber: 1,
        streetAddress: 1,
        city: 1,
        state: 1,
        zipCode: 1,
        type: 1,
        primaryAdmin: {
          name: 1,
        },
        practices: {
          name: 1,
        },
        isRedoxFacility: 1,
      }}
    />
  );
};
