export const ENCOUNTER_STATUS = {
   "Complete":"Billable",
   "Non Eligible":"Non Billable",
   "TCM Miss":"Incomplete",
   "Active":"Active",
   "Inactive":"Inactive",
   BILLABLE:"Billable",
   NON_BILLABLE:"Non Billable",
   INCOMPLETE:"Incomplete",
   
};
