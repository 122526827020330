export const TASK_TYPE_TO_SHOW = {
  ContactTask: 'Contact Task',
  MedicationTask: 'Medication Task',
  VisitTask: 'Visit Task',
  NotesTask: 'Notes Task',
  Form1823: 'Form 1823',
  InitialForm1823: 'Initial Form 1823',
};
export const TASK_TYPE = {
  ContactTask: 'ContactTask',
  MedicationTask: 'MedicationTask',
  VisitTask: 'VisitTask',
  NotesTask: 'NotesTask',
  Form1823: 'Form1823',
  InitialForm1823: 'InitialForm1823',
};
export const TASK_STATUS = {
  Complete: 'Complete',
  Approved: 'Approved',
  Reject: 'Reject',
};

export const TASK_TABLE_AUTO_COMPLETE_OPTIONS = [
  {label: 'Medication Task', value: 'MedicationTask'},
  {label: 'Contact Task', value: 'ContactTask'},
  {label: 'Visit Task', value: 'VisitTask'},
  {label: 'Tcm Notes', value: 'NotesTask'},
  {label: 'Form1823', value: 'Form1823'},
  {label: 'Initial Form1823', value: 'InitialForm1823'},
]