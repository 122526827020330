import {employerType} from '../constant/employerType';
import {useAuth} from '../modules/auth/hooks/useAuth';

export const isPracticeEmployee = () => {
  const {user} = useAuth();
  if (user?.employerType?.name === employerType?.practice) {
    return true;
  }
  return false;
};
