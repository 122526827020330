import styled from 'styled-components';
import theme from '../theme/Theme';

const BreadCrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap:8px;
  padding-left:8px;
  font-weight: 600;
  white-space: nowrap;
`;
const BreadCrumbText = styled.text`
  color: ${theme.colors.SIDEBAR_BACKGROUND_LOW};
`;
export {
    BreadCrumbContainer,
    BreadCrumbText
};
