import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useToast} from '../../../elitecare-frontend-components/packages/react-toast';
import ChatHeader from '../components/ChatHeader';
import ChatMessageArea from '../components/ChatMessageArea';
import MessageInputBox from '../components/MessageInputBox';
import {useLocation, useParams} from 'react-router-dom';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {routeLink, toasterMessage} from '../../../constant';
import {ChatMainContainer} from '../styles/Chat.style';
import {ChatMessageAreaContainer} from '../styles/ChatMessageArea.style';
import {useInvoke} from '../../../hooks';
import {fetchDataRequest} from '../chatRedux/actions';
import {DEFAULT_MESSAGE_COUNT} from '../constants/chat.constants';
import {getMembersData} from '../chatRedux/reducer';
import JumpToLatest from '../components/JumpToLatest';

const Chat = () => {
  const [oldMessages, setOldMessages] = useState([]);
  const [newMessages, setNewMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [canJump, setCanJump] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const chatElement = useRef(null);
  const {channelId, groupId} = useParams();
  const {state} = useLocation();
  const {user} = useAuth();
  const toast = useToast();
  const dispatch = useDispatch();

  const {group} = state;
  const membersData = getMembersData(groupId);

  const scrollToBottom = useCallback(
    (start, end) => {
      chatElement.current.scrollTo(start, end);
    },
    [chatElement],
  );
  const handleJump = () => {
    scrollToBottom(0, chatElement.current.scrollHeight);
    setCanJump(false);
  };

  const getMessageHistory = useCallback(
    async ({count = DEFAULT_MESSAGE_COUNT, timetoken = ''}) => {
      let options = {
        channel: channelId,
        count,
      };
      if (timetoken) {
        options['start'] = timetoken;
      }
      try {
        const history = await user?.pubnubInstance?.history(options);
        const messages = history?.messages?.map(message => ({
          text: message?.entry[0]?.text,
          timetoken: message?.timetoken,
          uuid: message?.entry[0]?.uuid,
        }));

        return messages;
      } catch (error) {
        toast({
          message: `${toasterMessage.getMessage.failure}: ${error}`,
          type: 'Error',
        });
      }
    },
    [],
  );

  const loadMore = async () => {
    try {
      if (hasMore) {
        const response = await getMessageHistory({
          timetoken: oldMessages.length
            ? oldMessages?.[0].timetoken
            : newMessages?.[0].timetoken,
        });
        if (response) {
          if (response.length < DEFAULT_MESSAGE_COUNT) {
            setHasMore(false);
          }
          setOldMessages(messages => [...response, ...messages]);
          scrollToBottom(
            chatElement.current.scrollTop,
            chatElement.current.scrollTop + response.length,
          );
        }
      }
    } catch (error) {
      toast({
        message: `${toasterMessage.loadMore.failure}:${error}`,
        type: 'Error',
      });
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      dispatch(fetchDataRequest(groupId));
      const message = await getMessageHistory({});
      setNewMessages(message || []);
      setIsLoading(false);
      // TODO: handle the scroll behavior on loading first time
      setTimeout(() => {
        scrollToBottom(0, chatElement.current.scrollHeight);
      }, 500);
    } catch (error) {
      setIsLoading(false);
      toast({
        message: `${toasterMessage.getMessage.failure}: ${error}`,
        type: 'Error',
      });
    }
    [groupId];
  });

  useEffect(() => {
    fetchData();
  }, [groupId]);

  useEffect(() => {
    if (user?.pubnubInstance) {
      user.pubnubInstance.subscribe({channels: [channelId]});
      const chatListener = {
        message: message => {
          const sentMessage = {
            text: message.message[0].text,
            sender: user.name,
            uuid: message.message[0].uuid,
            timetoken: message.timetoken,
          };
          setNewMessages(prevMessages => [...prevMessages, sentMessage]);
        },
      };
      user.pubnubInstance.addListener(chatListener);
      return () => {
        user.pubnubInstance.removeListener(chatListener);
        user.pubnubInstance.unsubscribe({channels: [channelId]});
      };
    }
  }, [channelId]);

  const invoke = useInvoke({
    method: 'put',
    close: false,
    eventSourceId: ['Groups'],
  });

  useEffect(() => {
    const lastMessage = newMessages[newMessages.length - 1];
    if (lastMessage) {
      invoke({
        uri: `${routeLink.updateLastMessageTimetoken}`,
        data: {
          lastReadTimeToken: lastMessage.timetoken,
          groupId: groupId,
        },
      });
    }
    lastMessage &&
      invoke({
        uri: `/v1/chatgroups/${group._id}`,
        data: {
          lastMessageTimeToken: lastMessage.timetoken,
        },
      });
    if (!canJump) {
      scrollToBottom(0, chatElement.current.scrollHeight);
    }
  }, [newMessages]);

  const sendMessage = useCallback(
    text => {
      try {
        setIsSending(true);
        user?.pubnubInstance?.publish({
          channel: channelId,
          message: [{text: text, uuid: user.PNChatMemberId}],
        });
        setIsSending(false);
        // TODO: handle the scroll behavior on sending
        setTimeout(() => {
          scrollToBottom(0, chatElement.current.scrollHeight);
        }, 50);
      } catch (error) {
        setIsSending(false);
        toast({
          message: `${toasterMessage.sendMessage.failure}: ${error}`,
          type: 'Error',
        });
      }
    },
    [channelId, user],
  );

  return (
    <ChatMainContainer>
      <ChatHeader group={group} />
      <ChatMessageAreaContainer>
        <ChatMessageArea
          membersData={membersData}
          isLoading={isLoading}
          chatElement={chatElement}
          loadMore={loadMore}
          messages={[...oldMessages, ...newMessages]}
          setCanJump={setCanJump}
        />
      </ChatMessageAreaContainer>
      {canJump && <JumpToLatest handleJump={handleJump} />}
      <MessageInputBox onSendMessage={sendMessage} isSending={isSending} />
    </ChatMainContainer>
  );
};

export default Chat;
