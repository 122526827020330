import React, {useEffect, useState} from 'react';
import {Col, Row} from '@unthinkable/react-core-components';
import {TextRenderer} from '@unthinkable/react-text-input';
import {getNumberOfLineStyle} from '@unthinkable/react-core-components/src/style-utility';
import {CChart} from '@coreui/react-chartjs';
import {TableHeader} from '../../../components/header/TableHeader';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {MyTaskTable} from './MyTasksTable';
import HttpAuth from '../../../services/HttpAuthService';
import moment from 'moment';
import {ActivityIndicator} from 'react-native-web';
import {
  isAdmin,
  isFacilityAdmin,
  isFacilityUser,
  isPracticeAdmin,
  isPracticeUser,
} from '../../../utils/checkUserType';
import {STATUS} from '../../../modules/user/constants/UserConstants';
import {isMobileOrTablet} from '../../../utils/isMobileOrTablet';
import {TASK_STATUS, TASK_TYPE} from '../../../constant/taskType';
import {ENCOUNTER_STATUS} from '../../../constant/encounterStatus';
import {routeLink} from '../../../constant';

const CompletionRateCard = ({
  completedCount,
  totalCount,
  loading,
  mobileOrTablet,
}) => {
  const completedPercentage =
    Math.round((completedCount / totalCount) * 100) || 0;

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 80).toFixed(2);
        ctx.font = fontSize + 'em "Open-Sans"';
        ctx.fontWeight = 800;
        ctx.textBaseline = 'top';
        var text = `${completedPercentage}%`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2.25;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
  return (
    <Row
      style={{
        alignItems: 'center',
        justifyContent: 'space-around',
        overflow: 'hidden',
        flexDirection: mobileOrTablet ? 'column-reverse' : 'row',
        flex: 1,
        padding: 16,
        border: '1px solid #D6DBE3',
        borderRadius: 16,
      }}>
      <TextRenderer
        value="30-Day TCM Completion Rate"
        styles={{
          text: {
            fontSize: 18,
            lineHeight: '28px',
            fontWeight: 700,
            color: '#19191A',
            ...getNumberOfLineStyle({numberOfLines: 2}),
          },
        }}
      />
      {loading ? (
        <ActivityIndicator
          style={{
            width: 160,
            height: 160,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      ) : (
        <CChart
          options={{
            cutout: 50,
          }}
          type="doughnut"
          redraw
          plugins={plugins}
          height={160}
          width={160}
          data={{
            datasets: [
              {
                backgroundColor: ['#D6DBE3', '#17B26A'],
                data: [100 - completedPercentage, completedPercentage],
              },
            ],
          }}
        />
      )}
    </Row>
  );
};

const CompletionCountCard = ({pendingCount, loading, mobileOrTablet}) => {
  return (
    <Row
      style={{
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: mobileOrTablet ? 'column-reverse' : 'row',
        flex: 1,
        padding: 16,
        border: '1px solid #D6DBE3',
        borderRadius: 16,
      }}
      gap={5}>
      <TextRenderer
        value="Transitional Care Tasks Outstanding"
        styles={{
          text: {
            fontSize: 18,
            lineHeight: '28px',
            fontWeight: 700,
            color: '#19191A',
            ...getNumberOfLineStyle({numberOfLines: 2}),
          },
        }}
      />
      <Col
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: 160,
          width: 160,
        }}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <TextRenderer
            value={pendingCount}
            styles={{
              text: {
                fontSize: 60,
                lineHeight: '72px',
                fontWeight: 600,
                color: '#19191A',
                ...getNumberOfLineStyle({numberOfLines: 2}),
              },
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export const Dashboard = props => {
  const {user} = useAuth();
  const [loading, setLoading] = useState(true);
  const [completedCount, setCompletedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [allPendingTasks, setAllPendingTasks] = useState(0);
  const [mobileOrTablet, setMobileOrTablet] = useState(!isMobileOrTablet());
  const taskFilter = {};
  const encounterFilter = {};

  if (isPracticeAdmin()) {
    taskFilter.primaryPractice = user?.practiceId;
    encounterFilter.primaryPractice = user?.practiceId;
  } else if (isFacilityAdmin()) {
    taskFilter.residence = user?.facilityId;
    encounterFilter.residence = user?.facilityId;
  } else if (!isAdmin()) {
    taskFilter.owners = user?._id;
    if (isFacilityUser()) {
      encounterFilter.residence = user?.facilityId;
    } else if (isPracticeUser()) {
      encounterFilter.primaryPractice = user?.practiceId;
    }
  }
  useEffect(() => {
    setMobileOrTablet(!mobileOrTablet);
  }, [isMobileOrTablet()]);

  useEffect(() => {
    const getData = async () => {
      const {data: allTcmTAsks} = await HttpAuth.get(
        routeLink.getTCMEncounter,
        {
          params: {
            filter: JSON.stringify({
              ...encounterFilter,
              status: {$ne: ENCOUNTER_STATUS.NON_BILLABLE},
              createdAt: {
                $gte: moment().utc().subtract(30, 'days').toDate(),
              },
            }),
            count: true,
          },
        },
      );
      setTotalCount(allTcmTAsks.count || 1);
      const {data: completedTcmTasks} = await HttpAuth.get(
        routeLink.getTCMEncounter,
        {
          params: {
            filter: JSON.stringify({
              ...encounterFilter,
              status: ENCOUNTER_STATUS.BILLABLE,
              createdAt: {
                $gte: moment().utc().subtract(30, 'days').toDate(),
              },
            }),
            count: true,
          },
        },
      );
      setCompletedCount(completedTcmTasks.count || 0);

      const {data: allPendingTasks} = await HttpAuth.get(routeLink.getTCMTask, {
        params: {
          filter: JSON.stringify({
            ...taskFilter,
            status: {$ne: TASK_STATUS.Complete},
            type: {$nin: [TASK_TYPE.InitialForm1823, TASK_TYPE.Form1823]},
            'patientId.status': STATUS.ACTIVE,
          }),
          count: true,
        },
      });
      setAllPendingTasks(allPendingTasks.count || 0);
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <Col
      gap={4}
      style={{
        flex: 1,
      }}>
      <TableHeader title={'Welcome back , ' + user.name} />
      <div
        style={{
          justifyContent: 'space-between',
          flexDirection: mobileOrTablet ? 'column' : 'row',
          flexWrap: 'wrap',
          display: 'flex',
          gap: 16,
        }}>
        <CompletionCountCard
          pendingCount={allPendingTasks}
          loading={loading}
          mobileOrTablet={mobileOrTablet}
        />
        <CompletionRateCard
          completedCount={completedCount}
          totalCount={totalCount}
          loading={loading}
          mobileOrTablet={mobileOrTablet}
        />
      </div>
      <MyTaskTable {...props} taskFilter={taskFilter} />
    </Col>
  );
};
