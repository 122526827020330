import {Dashboard} from './screens/Dashborad';

const stackRoutes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
  },
];

export default {
  stack: stackRoutes,
};
