import {USER_TYPE} from './userType';

export const action = {
  view: 'view',
  create: 'create',
  edit: 'edit',
  delete: 'delete',
  complete: 'complete',
  request: 'request',
  approve: 'approve',
  viewAll: 'viewAll',
  viewOwn: 'viewOwn',
};

export const entity = {
  user: 'User',
  practices: 'Practices',
  facilities: 'Facilities',
  chat: 'Chat',
  tcmTask: 'TcmTask',
  patient: 'Patients',
  careTeamMembers: 'CareTeamMembers',
  medication: 'Medication',
  diagnose:"Diagnose",
  facilityStats: 'Facility Stats',
  yourPatient: 'YourPatient',
  form1823: 'form1823',
  initialForm1823: 'initialForm1823',
  cronSettings:'Cron Settings'
};

export const authorization = {
  [USER_TYPE.ADMIN]: {
    User: [action.view, action.create, action.edit, action.delete],
    Practices: [action.view, action.create, action.edit, action.delete],
    Facilities: [action.view, action.create, action.edit, action.delete],
    'Audit Logs': [action.view],
    'New Requests': [action.view],
    Chat: [action.create, action.delete],
    TcmTask: [],
    Patients: [action.delete, action.viewAll, action.edit],
    CareTeamMembers: [action.delete],
    Medication: [],
    Diagnose: [],
    'Facility Stats': [action.viewAll],
    YourPatient: [action.viewAll],
    initialForm1823: [action.complete, action.view, action.edit],
    form1823: [action.complete, action.edit, action.view],
    'Cron Settings':[action.view , action.edit],
  },

  [USER_TYPE.PRACTICE_ADMIN]: {
    User: [action.view, action.create, action.edit, action.delete],
    Practices: [action.view, action.edit, action.request],
    Facilities: [],
    'New Requests': [action.view],
    'Audit Logs': [action.view],
    Chat: [action.create, action.delete],
    TcmTask: [action.complete],
    Patients: [action.delete, action.viewAll, action.edit],
    CareTeamMembers: [action.delete],
    Medication: [action.create, action.delete, action.approve],
    Diagnose: [action.approve , action.delete],
    'Facility Stats': [action.viewAll],
    YourPatient: [action.viewAll],
    form1823: [action.complete, action.edit, action.view],
    initialForm1823: [],
  },
  [USER_TYPE.PRACTICE_USER]: {
    User: [action.view],
    Practices: [],
    Facilities: [],
    'Audit Logs': [],
    Chat: [],
    TcmTask: [action.complete],
    Patients: [action.viewOwn, action.edit],
    Medication: [action.create, action.delete, action.approve],
    Diagnose: [action.approve ,action.delete],
    'Facility Stats': [action.viewOwn],
    YourPatient: [action.viewOwn],
    form1823: [action.complete, action.edit, action.view],
    initialForm1823: [],
  },
  [USER_TYPE.MEDICAL_PROVIDER]: {
    User: [action.view],
    Practices: [],
    Facilities: [],
    'Audit Logs': [],
    Chat: [],
    TcmTask: [action.complete],
    Patients: [action.viewOwn, action.edit],
    Medication: [action.create, action.delete, action.approve],
    Diagnose: [action.approve , action.delete],
    'Facility Stats': [action.viewOwn],
    YourPatient: [action.viewOwn],
    form1823: [action.approve, action.complete, action.edit, action.view],
    initialForm1823: [],
  },

  [USER_TYPE.FACILITY_ADMIN]: {
    User: [action.view, action.create, action.edit, action.delete],
    Practices: [],
    Facilities: [action.view, action.edit, action.request],
    'Audit Logs': [action.view],
    'New Requests': [action.view],
    Chat: [action.create, action.delete],
    TcmTask: [],
    Patients: [action.delete, action.viewAll, action.edit],
    CareTeamMembers: [action.delete],
    Medication: [],
    Diagnose: [],
    'Facility Stats': [action.viewOwn],
    YourPatient: [action.viewAll],
    form1823: [action.complete, action.edit, action.view],
    initialForm1823: [],
  },
  [USER_TYPE.FACILITY_USER]: {
    User: [action.view],
    Practices: [],
    Facilities: [],
    'Audit Logs': [],
    Chat: [],
    TcmTask: [],
    Patients: [action.viewOwn, action.edit],
    Medication: [],
    Diagnose: [],
    'Facility Stats': [action.viewOwn],
    YourPatient: [action.viewOwn],
    form1823: [],
    initialForm1823: [],
  },
};
