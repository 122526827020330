import React from 'react';
import {TextRenderer} from '../components/formEditors/Editors';

export const combineText = (value1, value2) => {
  return `${value1 || ''} ${value2 || ''}`.trim();
};
export const getPatientsFullName = ({
  FirstName,
  MiddleName,
  LastName,
  Render,
}) => {
  const fullName = `${LastName}, ${FirstName} ${MiddleName ? MiddleName : ''}`;

  return Render ? <TextRenderer value={fullName} /> : fullName;
};
